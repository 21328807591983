import React from 'react';
import ReactDOM from 'react-dom';
import {
  StylesProvider,
  createGenerateClassName,
} from '@mui/styles';
import Agenda from './Agenda.jsx';
import './intramuros.css';
import commonPaths from '../webpack/paths';

const generateClassName = createGenerateClassName({
  seed: 'events',
});

export function initEventsWidget(cityId, aggloId, cityINSEE, aggloSIREN) {
  const rootDiv = document.getElementById('intramuros_events');
  if (rootDiv) {
    // Remove previous instance if exists
    const previousChildNode = document.getElementById(
      'intramuros-event-content',
    );
    if (previousChildNode) {
      rootDiv.removeChild(previousChildNode);
    }

    const node = document.createElement('div');
    node.setAttribute('id', 'intramuros-event-content');

    const child = rootDiv.appendChild(node);

    console.log('Init IntraMuros Agenda widget');
    ReactDOM.render(
      <StylesProvider generateClassName={generateClassName}>
        <Agenda
          cityId={cityId}
          aggloId={aggloId}
          cityINSEE={cityINSEE}
          aggloSIREN={aggloSIREN}
        />
      </StylesProvider>,
      child,
    );
  } else {
    console.log('No tag with id: intramuros_events');
  }
}

// Launch widget
console.log(`Widget Agenda v${commonPaths?.widgetVersion} chargé.`);
console.log('TARGET_ENV: ' + process.env.TARGET_ENV);
console.log('NODE_ENV: ' + process.env.NODE_ENV);

const {
  intramurosCityId,
  intramurosAggloId,
  intramurosCityINSEE,
  intramurosAggloSIREN,
} = window;

console.log('City ID = ' + intramurosCityId);
console.log('Agglo ID = ' + intramurosAggloId);

if (
  intramurosCityId ||
  intramurosAggloId ||
  intramurosCityINSEE ||
  intramurosAggloSIREN
) {
  initEventsWidget(
    intramurosCityId,
    intramurosAggloId,
    intramurosCityINSEE,
    intramurosAggloSIREN,
  );
}
