import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disabletypography='true' className={classes.root}>
      <Typography style={{ fontSize: 16 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class DialogBox extends React.Component {
  state = {
    open: false,
  };

  static getDerivedStateFromProps(props, currentState) {
    if (currentState.open !== props.open) {
      return {
        open: props.open,
      };
    }
    return null;
  }

  render() {
    const { open } = this.state;
    const { handleClose, title, content } = this.props;

    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={false}
        >
          {title ? (
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {title}
            </DialogTitle>
          ) : null}

          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ fontSize: 16 }}
            >
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogBox.propTypes = {
  handleClose: PropTypes.objectOf(PropTypes.object()).isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default DialogBox;
